// Copied in gatsby-browser.js
function getCookie(cname: string) {
  const name = cname + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const canUseDOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
)

function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

const ANALYTICS_COOKIE_NAME = "analytics-opt-out"
export function checkOptOut() {
  return getCookie(ANALYTICS_COOKIE_NAME) === "yes-please"
}

export function setOptOut(optOut: boolean) {
  setCookie(
    ANALYTICS_COOKIE_NAME,
    optOut ? "yes-please" : "whatever-man",
    100000
  )
}

export function formatDateYMD(d: Date) {
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
}

export function getNumberWithOrdinal(n: number) {
  const s = ["th", "st", "nd", "rd"]
  const v = n % 100
  return `${n}${s[(v - 20) % 10] || s[v] || s[0]}`
}

interface MarkdownRemarkFrontmatterVerified {
  readonly title: string
  readonly path: string
  readonly parent: string
  readonly date: string
  readonly type: string
  readonly thumbnail: GatsbyTypes.File
  readonly tags?: string[]
}

// create-pages.ts verifies that frontmatter is correctly formed before adding it
export function expandFrontmatter(
  frontmatter: GatsbyTypes.MarkdownNodeFragment["frontmatter"]
): MarkdownRemarkFrontmatterVerified {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (frontmatter as any) as MarkdownRemarkFrontmatterVerified
}
