import React from "react"

import styled from "styled-components"
import { Scale, scale, PaddingProps } from "./util"
import { padStyle } from "./box"
import css from "csstype"

interface ClusterProps {
  gap: Scale
  justify?:
    | css.ContentDistribution
    | css.ContentPosition
    | "left"
    | "normal"
    | "right"
}

const OuterCluster = styled.div<ClusterProps & PaddingProps>`
  --space: ${p => scale(p.gap)};
  overflow: hidden;

  ${padStyle}

  & > * {
    display: flex;
    flex-wrap: wrap;
    justify-content: ${p => p.justify || "flex-start"};
    align-items: center;
    margin: calc(var(--space) / 2 * -1);
  }

  & > * > * {
    margin: calc(var(--space) / 2);
  }
`

export const Cluster: React.FC<ClusterProps & PaddingProps> = ({
  children,
  ...props
}) => (
  <OuterCluster {...props}>
    <div>{children}</div>
  </OuterCluster>
)
