import { MaxWidthProperty } from "csstype"
import styled from "styled-components"
import { Scale, scale } from "./util"

interface CenterProps {
  maxWidth?: MaxWidthProperty<never>
  gutter?: Scale
  centerAll?: boolean
}

export const Center = styled.div<CenterProps>`
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: ${p => (p.maxWidth !== undefined ? p.maxWidth : "var(--measure)")};
  padding-left: ${p => scale(p.gutter)};
  padding-right: ${p => scale(p.gutter)};
  ${p =>
    p.centerAll
      ? `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;`
      : ""}
`
