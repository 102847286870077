import styled from "styled-components"
import { Link } from "gatsby"

export const SubTitle = styled.div`
  font-size: var(--s0);
  font-family: var(--font-title);
  font-weight: 600;
`

export const SubtleTitle = styled.div`
  color: var(--color-gray);
  font-size: var(--s0);
  font-family: var(--font-title);
  font-weight: 400;
`

export const ConstrainedP = styled.p<{ lines: number }>`
  max-height: ${p => `calc(${p.lines}em * var(--line-height))`};
  overflow-y: hidden;
  text-overflow: ellipsis;
`

export const PlainLink = styled(Link)`
  color: var(--color-dark);
  text-decoration: none;
  &:hover {
    color: var(--color-gray-dark);
    text-decoration: underline;
  }
`
