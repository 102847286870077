import { Link } from "gatsby"
import React from "react"
import { Stack } from "../layout/stack"
import styled from "styled-components"
import { HBar, Logo } from "../elements/motifs"
import { Switcher } from "../layout/switcher"
import { Cluster } from "../layout/cluster"
import { Sidebar, SidebarGrow, SidebarFixed } from "../layout/sidebar"
import { GUTTER } from "../layout/util"
import { NavRef } from "../types"

const NavLink = styled(Link).attrs(p => ({
  ...p,
  activeStyle: { borderColor: "var(--color-light)" },
  partiallyActive: true,
}))`
  display: inline-block;
  font-family: var(--font-title);
  font-weight: 600;
  text-decoration: none;
  color: var(--color-light);
  text-align: left;

  &:hover,
  .active {
    border-color: var(--color-light) !important;
  }

  border-left: var(--scale-vline) solid transparent;
  border-bottom: none;
  padding-left: var(--scale-vline);
  @media (max-width: 768px) {
    border-bottom: var(--scale-hline) solid transparent;
    border-left: none;
    padding-left: 0;
  }
  .wide & {
    border-bottom: var(--scale-hline) solid transparent;
    border-left: none;
    padding-left: 0;
  }
`

export const StackingCluster = styled(Cluster)`
  & > div {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    & > div {
      flex-direction: row;
    }
  }
  .wide & > div {
    flex-direction: row;
  }
`

const NavCluster = styled(StackingCluster)`
  & > div {
    align-items: flex-start;
  }
`

const SidebarDeco = styled.div`
  flex-basis: 20%;
  flex-grow: 1;

  @media (max-width: 768px) {
    flex-basis: var(--scale-gutter);
  }
  .wide & {
    flex-basis: var(--scale-gutter) !important;
  }
`

const NavLogo = styled(Logo)`
  color: var(--color-light);
  width: 5rem;

  @media (max-width: 768px) {
    width: 2rem;
  }
  .wide & {
    width: 2rem;
  }
`

const CenterBig = styled.div`
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    margin: 0;
  }
  .wide & {
    margin: 0;
  }
`

export const MainNav: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <Sidebar
    style={{ backgroundColor: "var(--color-primary)" }}
    className={className}
  >
    <SidebarDeco />
    <SidebarGrow
      style={{
        borderLeft: "var(--s-2) solid var(--color-secondary)",
      }}
    >
      <StackingCluster gap={GUTTER} p={GUTTER} justify="space-between">
        <CenterBig>
          <Link to="/">
            <NavLogo />
          </Link>
        </CenterBig>
        <NavCluster gap={-1}>
          <NavLink to="/about/">About</NavLink>
          <NavLink to="/services/">Services</NavLink>
          <NavLink to="/projects/">Projects</NavLink>
          <NavLink to="/blog/">Blog</NavLink>
        </NavCluster>
        {children}
      </StackingCluster>
    </SidebarGrow>
  </Sidebar>
)

interface SecondaryNavProps {
  parents: NavRef[]
  siblings: NavRef[]
  activePath: string
  navChildren?: NavRef[]
}

const SecondaryLink = styled(Link)`
  font-family: var(--font-title);
  font-weight: 600;
  color: var(--color-dark);
  padding: var(--s-3) 0 var(--s-3) 0;
  display: block;
`

const SecondaryNavBox = styled.div<{
  indent: number
  active?: boolean
}>`
  padding-left: calc(var(--s-1) * (${p => p.indent} + 1));
  border-left-width: var(--scale-vline);
  border-left-style: solid;
  border-color: ${p =>
    p.active ? "var(--color-secondary) !important" : "var(--color-gray-light)"};
  &:hover {
    border-color: var(--color-gray-dark);
  }
`

const SecondaryNavItem: React.FC<{
  title: string
  path: string
  active?: boolean
  indent: number
}> = ({ title, path, active, indent }) => (
  <SecondaryNavBox indent={indent} active={active}>
    <SecondaryLink to={path}>{title}</SecondaryLink>
  </SecondaryNavBox>
)

const SecondaryNavSidebar = styled(Sidebar)`
  @media (max-width: 768px) {
    display: none;
  }
`

export const SecondaryNav: React.FC<SecondaryNavProps> = ({
  parents,
  siblings,
  activePath,
  navChildren,
}) => (
  <SecondaryNavSidebar>
    <SidebarFixed fixedWidth="20%" />
    <SidebarGrow>
      <Stack gap={-4}>
        {parents.map(({ title, path }, i) => (
          <SecondaryNavItem title={title} path={path} indent={i} key={path} />
        ))}
        {siblings.map(({ title, path }) => (
          <SecondaryNavItem
            title={title}
            path={path}
            indent={parents.length}
            active={path === activePath}
            key={path}
          />
        ))}
        {navChildren
          ? navChildren.map(({ title, path }) => (
              <SecondaryNavItem
                title={title}
                path={path}
                indent={parents.length + 1}
                key={path}
              />
            ))
          : null}
      </Stack>
    </SidebarGrow>
  </SecondaryNavSidebar>
)

const FooterNavLink = styled(Link)`
  display: inline-block;
  font-family: var(--font-title);
  font-weight: 600;
  text-decoration: none;
  color: var(--color-dark);

  &:hover {
    text-decoration: underline;
  }
`

export const FooterNav = () => (
  <Switcher minWidth="40ch" gap={1}>
    <FooterNavGroup title="Featured Projects">
      <FooterNavLink to="/projects/generator">Generator</FooterNavLink>
      <FooterNavLink to="/projects/mr-fixums-handwheel-repair">
        Handwheel Repair
      </FooterNavLink>
    </FooterNavGroup>
    <FooterNavGroup title="Company Info">
      <FooterNavLink to="/about/">About Us</FooterNavLink>
      <FooterNavLink to="/about/contact">Contact</FooterNavLink>
      <FooterNavLink to="/about/affiliate-disclosure">
        Affiliate Disclosure
      </FooterNavLink>
      <FooterNavLink to="/about/privacy">Privacy Policy</FooterNavLink>
    </FooterNavGroup>
  </Switcher>
)

const FooterTitle = styled.div`
  color: var(--color-dark);
  font-family: var(--font-title);
  font-weight: 600;
  font-size: var(--s1);
`

export const FooterNavGroup: React.FC<{ title: string }> = ({
  title,
  children,
}) => (
  <Stack gap={-1}>
    <FooterTitle>{title}</FooterTitle>
    <HBar />
    {children}
  </Stack>
)
