import React, { HTMLAttributes } from "react"
import styled from "styled-components"
import { padStyle } from "./box"
import { PaddingProps, Scale, scale } from "./util"

interface SwitcherProps {
  minWidth: string
  gap?: Scale
  limit?: number
  justify?: string
}

const OuterSwitcher = styled.div<SwitcherProps & PaddingProps>`
  --gap: ${p => scale(p.gap) || "0"};
  ${padStyle}

  & > * {
    display: flex;
    flex-wrap: wrap;
    ${p => (p.justify ? `justify-content: ${p.justify};` : "")}
    margin: calc((var(--gap) / 2) * -1);
  }

  & > * > * {
    flex-grow: 1;
    flex-basis: calc((${p => p.minWidth} - (100% - var(--gap))) * 999);
    margin: calc(var(--gap) / 2);
  }

  ${p =>
    p.limit
      ? `
  & > * > :nth-last-child(n + ${p.limit}),
  & > * > :nth-last-child(n + ${p.limit}) ~ * {
    flex-basis: 100%;
  }`
      : ""}
`

export const Switcher: React.FC<SwitcherProps &
  PaddingProps &
  HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
  <OuterSwitcher {...props}>
    <div>{children}</div>
  </OuterSwitcher>
)
