import React from "react"
import { Stack } from "../layout/stack"
import { HBar } from "../elements/motifs"
import { Helmet } from "react-helmet"

export const PageHeader: React.FC = ({ children }) => (
  <>
    <Stack gap={-2}>{children}</Stack>
    <HBar />
  </>
)

export const PageTitle: React.FC<{ title: string }> = ({ title }) => (
  <>
    <h1>{title}</h1>
    <Helmet defer={false}>
      <title>{title} - Specific Solutions</title>
      <meta property="og:title" content={`${title} - Specific Solutions`} />
    </Helmet>
  </>
)

export const PageMeta: React.FC<{
  description?: string
  thumbnail?: string
}> = ({ description, thumbnail }) => (
  <Helmet defer={false}>
    {description ? <meta name="description" content={description} /> : null}
    {thumbnail ? <meta property="og:image" content={thumbnail} /> : null}
  </Helmet>
)
