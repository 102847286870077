import styled from "styled-components"
import { padStyle } from "./box"
import { PaddingProps, Scale, scale } from "./util"

interface StackProps {
  gap?: Scale
  orientation?: "vertical" | "horizontal"
}

export const Stack = styled.div<StackProps & PaddingProps>`
  display: flex;
  flex-direction: ${p => (p.orientation === "horizontal" ? "row" : "column")};
  justify-content: flex-start;

  ${padStyle}

  & > * {
    ${p =>
      p.orientation === "horizontal"
        ? `
    margin-left: 0;
    margin-right: 0;`
        : `
    margin-top: 0;
    margin-bottom: 0;`}
  }

  & > * + * {
    ${p =>
      p.orientation === "horizontal"
        ? `
    margin-left: ${scale(p.gap)};
    `
        : `
    margin-top: ${scale(p.gap)};
    `}
  }

  &:only-child {
    height: 100%;
  }
`

export const StackSplit = styled.div`
  margin-bottom: auto;
`
