import React, { HTMLAttributes } from "react"
import styled from "styled-components"
import { padStyle } from "./box"
import { PaddingProps, Scale, scale } from "./util"

interface SidebarProps {
  gap?: Scale
}

const OuterSidebar = styled.div<SidebarProps>`
  overflow: hidden;
  --gap: ${p => scale(p.gap) || "0"};
  & > * {
    display: flex;
    flex-wrap: wrap;
    margin: calc(var(--gap) / 2 * -1);
  }

  & > * > * {
    margin: calc(var(--gap) / 2);
    flex-grow: 1;
  }
`

export const Sidebar: React.FC<SidebarProps &
  HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => (
  <OuterSidebar {...props}>
    <div>{children}</div>
  </OuterSidebar>
)

interface SidebarFixedProps {
  fixedWidth: string
}

export const SidebarFixed = styled.div<SidebarFixedProps & PaddingProps>`
  flex-basis: ${p => p.fixedWidth};
  flex-grow: 1;

  ${padStyle}
`

interface SidebarGrowProps {
  minWidth?: string
}
export const SidebarGrow = styled.div<SidebarGrowProps & PaddingProps>`
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(${p => p.minWidth || "50%"} - var(--gap));

  ${padStyle}
`
