import React from "react"
import styled from "styled-components"
import { SwirlyHR } from "../elements/motifs"
import { Center } from "../layout/center"
import { Sidebar, SidebarFixed, SidebarGrow } from "../layout/sidebar"
import { Stack, StackSplit } from "../layout/stack"
import { GUTTER } from "../layout/util"
import { FooterNav, MainNav } from "./nav"

const pageContainer: React.FC = ({ children, ...props }) => (
  <Center maxWidth="1200px" {...props}>
    <Stack style={{ height: "100%" }}>{children}</Stack>
  </Center>
)

export const PageContainer = styled(pageContainer)`
  min-height: 100vh;
`

export const PageOuterSidebar = styled(Sidebar)`
  @media (max-width: 768px) {
    & > div {
      flex-direction: column;
    }
    & > * {
      flex-wrap: unset;
    }
  }
`

const SIDEBAR_WIDTH = "calc(12rem + 3vw)"

const pageSidebar: React.FC = ({ children, ...props }) => (
  <SidebarFixed fixedWidth={SIDEBAR_WIDTH} {...props}>
    <Stack gap={0}>
      <MainNav />
      {children}
    </Stack>
  </SidebarFixed>
)

export const PageSidebar = styled(pageSidebar)`
  order: 100;

  @media (max-width: 768px) {
    order: 0;
    flex-basis: 0;
  }
`

const pageMain: React.FC = ({ children, ...props }) => (
  <SidebarGrow {...props} style={{ minWidth: "0" }}>
    <Stack p={1} gap={1}>
      {children}
    </Stack>
  </SidebarGrow>
)

export const PageMain = styled(pageMain)``

const LogoText = styled.div`
  font-family: var(--font-title);
  font-weight: 600;
  font-size: var(--s0);
`

export const PageFooter: React.FC = () => (
  <>
    <StackSplit />
    <SwirlyHR />
    <Sidebar>
      <SidebarGrow p={GUTTER}>
        <FooterNav />
      </SidebarGrow>
      <SidebarFixed
        fixedWidth={SIDEBAR_WIDTH}
        p={GUTTER}
        style={{ textAlign: "right" }}
      >
        <LogoText>Specific Solutions LLC</LogoText>
        <LogoText style={{ fontSize: "var(--s-1)" }}>Portland, OR</LogoText>
      </SidebarFixed>
    </Sidebar>
  </>
)
