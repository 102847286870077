export type Scale = -5 | -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5

export const GUTTER: Scale = 1

export function scale(s?: Scale) {
  return s !== undefined ? `var(--s${s})` : "0"
}

export interface PaddingProps {
  p?: Scale
}
