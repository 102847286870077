import styled from "styled-components"
import { Scale, scale } from "./util"

interface PaddingProps {
  p?: Scale
}

export function padStyle({ p }: PaddingProps) {
  return p !== undefined
    ? `
    padding: ${scale(p)};
  `
    : ""
}

export const Box = styled.div`
  ${padStyle}
`
