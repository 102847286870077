import React, { SVGAttributes } from "react"
import styled from "styled-components"
import { SeriesInfo } from "../types"
import { SubtleTitle, PlainLink } from "./typography"
import { getNumberWithOrdinal } from "../util"

export const HBar = styled.div`
  height: var(--scale-hline);
  width: var(--s4);
  background-color: var(--color-primary);
`

export const Logo: React.FC<SVGAttributes<unknown>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 116.114 116.114"
    fill="currentColor"
    {...props}
  >
    <path d="M69.669 116.114H46.446v-1.981A34.824 34.824 0 010 81.279h23.223a11.611 11.611 0 1023.222 0v32.853c.651-.23 1.306-.484 1.948-.756a34.805 34.805 0 0021.275-32.1zM46.444 80.807a10.955 10.955 0 00-5.507-9.718c-3.323-2.129-7.857-3.427-12.657-4.8-3.28-.939-6.664-1.909-9.931-3.209a33.847 33.847 0 01-9.123-5.166 23.108 23.108 0 01-6.666-8.843 29.4 29.4 0 01-1.9-6.337 42.987 42.987 0 01-.66-7.9A34.86 34.86 0 0146.446 1.981v32.843a11.611 11.611 0 00-23.222.01 11.456 11.456 0 005.558 10.344c3.388 2.222 8.012 3.55 12.909 4.956 1.555.446 3.161.908 4.755 1.406v29.267zm23.225-.056C69.51 60.445 57.6 55.026 46.446 51.54V34.834h23.222v11.611h11.611a11.611 11.611 0 100-23.222h-11.61V34.6A34.876 34.876 0 0048.393 2.737c-.64-.271-1.3-.525-1.948-.756V0h34.834a34.843 34.843 0 0113.56 66.931 34.616 34.616 0 01-13.559 2.737H69.669z" />
  </svg>
)

const SwirlyCueWrapper = styled.svg`
  color: var(--color-primary);
  fill: none;
  stroke: #0d5389;
  stroke-width: 5px;
`

export const SwirlyCue: React.FC<SVGAttributes<unknown>> = props => (
  <SwirlyCueWrapper
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 102.785 36.248"
    stroke="currentColor"
    {...props}
  >
    <path d="M33.365 28.2s-7.308 5.615-15.937 5.426A15.389 15.389 0 012.5 18.19c0-8 6.033-15.69 15.2-15.69 4.89 0 10.114 1.83 34.06 16.067s28.676 15.18 32.56 15.18c10.716-.119 15.506-8.05 15.936-15.18A15.492 15.492 0 0084.321 2.594a22.758 22.758 0 00-16.107 6.892M61.904 15.444l-14.382 9.588M54.713 10.65l-14.382 9.588" />
  </SwirlyCueWrapper>
)

const SwirlyHRWrap = styled.div`
  height: var(--scale-gutter);
  display: flex;
  padding-left: var(--scale-gutter);
  padding-right: var(--scale-gutter);
`

const SwirlyHRBar = styled.div`
  height: var(--scale-hline);
  background-color: var(--color-primary);
  flex-grow: 999;
  align-self: center;
`

const SwirlyHRCue = styled(SwirlyCue)`
  height: 100%;
  flex-basis: auto;
  margin-left: var(--scale-gutter);
  margin-right: var(--scale-gutter);
`

export const SwirlyHR: React.FC = () => (
  <SwirlyHRWrap>
    <SwirlyHRBar />
    <SwirlyHRCue />
    <SwirlyHRBar />
  </SwirlyHRWrap>
)

export const SeriesText: React.FC<{ seriesInfo: SeriesInfo }> = ({
  seriesInfo,
}) => (
  <SubtleTitle>
    {seriesInfo.current ? (
      <span>
        {getNumberWithOrdinal(seriesInfo.current)} article in{" "}
        <PlainLink to={seriesInfo.path}>{seriesInfo.title}</PlainLink>
      </span>
    ) : (
      `${seriesInfo.total} article series`
    )}
  </SubtleTitle>
)
